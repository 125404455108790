import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MetaMaskProvider } from "@metamask/sdk-react";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <MetaMaskProvider
            debug={false} // Activez le mode débogage
            sdkOptions={{
                dappMetadata: {
                    name: "KwakOnOne Mint",
                    url: window.location.href,
                },
                logging: {
                    developerMode: false, // Activez le mode développeur pour plus de logs
                },
            }}
        >
            <App />
        </MetaMaskProvider>
    </React.StrictMode>
);

// Mesurer les performances
reportWebVitals();
