import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSDK } from "@metamask/sdk-react"; // Importer le SDK
import {
    Button,
    Container,
    Typography,
    Box,
    Card,
    CardContent,
    CircularProgress,
} from "@mui/material";
import "./App.css";

function App() {
    const { sdk, provider, account } = useSDK(); // Utiliser le SDK MetaMask
    const [hasAttemptedConnect, setHasAttemptedConnect] = useState(false); // État pour suivre si l'utilisateur a essayé de se connecter
    const desiredChainId = "0xf4233"; // ID de la chaîne OneFinity
    const [contract, setContract] = useState(null);
    const [hasMinted, setHasMinted] = useState(false); // Vérifie si l'utilisateur a déjà minté
    const [currentAccount, setCurrentAccount] = useState(null);
    const [nftImage, setNftImage] = useState(null);
    const [mintSuccess, setMintSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mintedTokenId, setMintedTokenId] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [buttonLabel, setButtonLabel] = useState("Connect");
    const contractAddress = "0x0C186821FBE69be042e4EbE0De82F8914fACe050";

    const abi = [
        {
            inputs: [],
            name: "mint",
            outputs: [],
            stateMutability: "payable",
            type: "function",
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: "address",
                    name: "owner",
                    type: "address",
                },
                {
                    indexed: false,
                    internalType: "uint256",
                    name: "tokenId",
                    type: "uint256",
                },
            ],
            name: "Minted",
            type: "event",
        },
    ];

    useEffect(() => {
        if (sdk) {
            sdk.on("chainChanged", handleChainChanged);
            sdk.on("accountsChanged", handleAccountsChanged);
        }

        return () => {
            if (sdk) {
                // Nettoyer les écouteurs d'événements
                sdk.removeListener("chainChanged", handleChainChanged);
                sdk.removeListener("accountsChanged", handleAccountsChanged);
            }
        };
    }, [sdk]);

    const handleChainChanged = async () => {
        // Réinitialiser l'état
        resetState();
        setButtonLabel("Connect"); // Remettre le bouton à Connect
    };

    const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
            resetState();
            setErrorMessage("Please connect to MetaMask.");
            setButtonLabel("Connect");
        } else {
            setHasAttemptedConnect(true); // L'utilisateur a essayé de se connecter
            setButtonLabel("Check Network");
        }
    };

    const resetState = () => {
        setContract(null);
        setHasMinted(false);
        setMintSuccess(false);
        setNftImage(null);
    };

    const connectWallet = async () => {
        // Vérifiez que le SDK est chargé
        if (!sdk) {
            setErrorMessage("MetaMask is required to use this feature!");
            return;
        }

        // Effacez le message d'erreur précédent
        setErrorMessage("");

        try {
            // Connexion via le SDK
            const accounts = await sdk.connect(); // Utilisez le SDK pour vous connecter
            const connectedAccount = accounts[0]; // Obtenez le compte connecté
            setCurrentAccount(connectedAccount);

            // Utilisez le provider du SDK
            const tempProvider = sdk.provider; // Obtenez le provider depuis le SDK

            // Vérifiez si l'utilisateur est sur le bon réseau
            const isNetworkSelected = await checkNetworkSelection();
            if (!isNetworkSelected) {
                setButtonLabel("Switch to OneFinity Network");
                setInfoMessage("OneFinity will be added if necessary.");
                return; // Sortir si l'utilisateur n'est pas sur le bon réseau
            }

            // Créez une instance du contrat avec le provider du SDK
            const tempContract = new ethers.Contract(
                contractAddress,
                abi,
                tempProvider // Utilisez le provider du SDK ici
            );
            setContract(tempContract);

            // Vérifiez si l'utilisateur a déjà minté
            const hasAlreadyMinted = localStorage.getItem(connectedAccount);
            if (hasAlreadyMinted) {
                setHasMinted(true);
                setButtonLabel("Disconnect");
                setInfoMessage("You have already minted an NFT!");
                return;
            }

            setButtonLabel("Mint");
        } catch (error) {
            console.error("Error connecting to MetaMask:", error);
            setErrorMessage(
                "An error occurred while connecting. Please try again."
            );
        }
    };

    const disconnectWallet = () => {
        resetState();
        setButtonLabel("Connect"); // Remettre le bouton à Connect
        setInfoMessage(""); // Effacer le message d'information
    };

    const switchNetwork = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            setErrorMessage("MetaMask is not installed or not accessible.");
            return;
        }

        const desiredChainId = "0xf4233"; // ID de la chaîne OneFinity

        try {
            // Tentez d'ajouter le réseau directement
            await addNetwork(); // Appelez addNetwork

            // Vérifiez ensuite si le réseau a été ajouté
            const newChainId = await ethereum.request({
                method: "eth_chainId",
            });

            // Essayez de passer au réseau
            await ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: desiredChainId }],
            });

            setButtonLabel("Mint"); // Affichez le bouton Mint
        } catch (error) {
            if (error.code === 4902) {
                setErrorMessage(
                    "The network was not recognized. Please try again."
                );
            } else {
                setErrorMessage("An error occurred while switching networks.");
            }
        }
    };

    const addNetwork = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            setErrorMessage("MetaMask is not installed or not accessible.");
            return;
        }

        try {
            const params = [
                {
                    chainId: desiredChainId,
                    chainName: "OneFinity",
                    rpcUrls: ["https://testnet-rpc.onefinity.network"],
                    nativeCurrency: {
                        name: "ONE",
                        symbol: "ONE",
                        decimals: 18,
                    },
                    blockExplorerUrls: ["https://explorer.onefinity.network"],
                },
            ];

            // Tentative d'ajout du réseau
            await ethereum.request({
                method: "wallet_addEthereumChain",
                params: params,
            });

            setInfoMessage(
                "Network added. Please switch to the OneFinity network."
            );
        } catch (addError) {
            console.error("Failed to add network:", addError);
            setErrorMessage("Failed to add the network. Please try again.");
        }
    };

    const mintNFT = async () => {
        // Réinitialiser les messages avant de commencer
        setErrorMessage("");
        setInfoMessage("");

        const balance = await checkBalance();
        if (!balance) {
            setErrorMessage("Unable to retrieve balance. Please try again.");
            return;
        }

        if (balance.lt(ethers.utils.parseEther("0.01"))) {
            setErrorMessage(
                "Insufficient balance for transaction! Please add ONE to your wallet."
            );
            return;
        }

        if (!contract) {
            setErrorMessage("The contract is not connected yet!");
            return;
        }

        try {
            setLoading(true);
            const signer = new ethers.providers.Web3Provider(
                window.ethereum
            ).getSigner();
            const tx = await contract.connect(signer).mint({
                value: ethers.utils.parseEther("0.01"),
            });

            const receipt = await tx.wait();
            const mintedEvent = receipt.events.find(
                (event) => event.event === "Minted"
            );
            const tokenId = mintedEvent.args.tokenId.toNumber();
            setMintedTokenId(tokenId);

            setHasMinted(true);
            setMintSuccess(true);
            setNftImage("/assets/Buddha_ONeF.png");
            setInfoMessage("Mint successful!");

            localStorage.setItem(account, true);

            setButtonLabel("Disconnect");
        } catch (error) {
            console.error("Error during minting:", error);
            setErrorMessage(
                "An error occurred during minting. Please try again."
            );
        } finally {
            setLoading(false);
        }
    };

    const checkBalance = async () => {
        if (!provider) {
            setErrorMessage(
                "Provider is not available. Please connect your wallet."
            );
            return null;
        }

        try {
            const accounts = await provider.request({ method: "eth_accounts" }); // Récupérer les comptes
            const address = accounts[0];
            const balance = await provider.request({
                method: "eth_getBalance",
                params: [address, "latest"],
            });
            return ethers.BigNumber.from(balance); // Convertir le solde en BigNumber
        } catch (error) {
            console.error("Error retrieving balance:", error);
            setErrorMessage(
                "Unable to retrieve balance. Please ensure you are connected to the OneFinity network."
            );
            return null;
        }
    };

    const checkNetworkSelection = async () => {
        try {
            const chainId = await provider.request({ method: "eth_chainId" });
            if (chainId.toLowerCase() !== desiredChainId) {
                return false; // L'utilisateur n'est pas sur le bon réseau
            }
        } catch (error) {
            console.error("Error checking network selection:", error);
            return false; // Échec de la vérification
        }
        return true; // L'utilisateur est sur le bon réseau
    };

    const handleButtonClick = async () => {
        if (buttonLabel === "Connect") {
            await connectWallet();
        } else if (buttonLabel === "Switch to OneFinity Network") {
            await switchNetwork();
        } else if (buttonLabel === "Mint") {
            await mintNFT();
        } else if (buttonLabel === "Disconnect") {
            disconnectWallet(); // Ajout de la déconnexion
        }
    };

    return (
        <Container
            maxWidth="sm"
            style={{ textAlign: "center", marginTop: "50px" }}
        >
            <Box>
                {currentAccount && (
                    <Typography
                        className="font-personal-app"
                        variant="body1"
                        style={{ marginBottom: "20px" }}
                    >
                        Connected with address: {currentAccount}
                    </Typography>
                )}
                <Button
                    className="font-personal-app"
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    disabled={loading}
                    style={{
                        marginBottom: "20px",
                        backgroundColor:
                            buttonLabel === "Mint" ? "green" : "blueviolet",
                        color: "white",
                    }}
                >
                    {loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        buttonLabel
                    )}
                </Button>
                {/* Affichage des messages d'erreur et d'information */}
                {errorMessage && (
                    <Typography
                        variant="body1"
                        color="error"
                        style={{ marginBottom: "20px" }}
                    >
                        {errorMessage}
                    </Typography>
                )}
                {infoMessage && (
                    <Typography
                        variant="body1"
                        color="primary"
                        style={{ marginBottom: "20px" }}
                    >
                        {infoMessage}
                    </Typography>
                )}
                {mintSuccess && (
                    <Card
                        style={{
                            maxWidth: "600px",
                            width: "100%",
                            margin: "0 auto",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                        }}
                    >
                        <img
                            src={nftImage}
                            alt="Minted NFT"
                            style={{
                                width: "100%",
                                height: "auto",
                                display: "block",
                                backgroundColor: "transparent",
                            }}
                        />
                        <CardContent className="card-content">
                            <Typography
                                className="font-personal-app"
                                variant="h6"
                                component="p"
                                style={{ marginBottom: "10px" }}
                            >
                                Mint successful!
                            </Typography>
                            {mintedTokenId && (
                                <>
                                    <Typography
                                        variant="body1"
                                        className="minted-text font-personal-app"
                                    >
                                        You minted NFT number{" "}
                                        <strong>{mintedTokenId}</strong> out of
                                        1000. This is your Token ID.
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="minted-text font-personal-app"
                                    >
                                        Smart Contract of the NFT is{" "}
                                        <strong>{contractAddress}</strong>.
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="minted-text font-personal-app"
                                    >
                                        Now wait for indexation to see it in
                                        your wallet.
                                    </Typography>
                                </>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </Container>
    );
}

export default App;
